<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="护理管理系统" describe="护理管理信息系统具有管理全院护理人力资源、考核、培训、意外事件、满意度、工作量统计、危重病人访视、护理质控检查、护士长排班、实习护生、进修人员等功能。护士长科内提交信息，护理部根据电脑自动提示审管全院上报。利用护理管理系统,护理部不再需要人工整理科室上报文件，自动生成各类月度、季度、半年、全年统计图表，如意外事件发生数、平均满意度与排名、考核结果、培训情况、三级护理质量检查合格率/均分、全院人员考勤与值班小时数等等。护理管理系统可有效提高护理部管理执行力，是高效管理必备助手。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <img src="@/assets/product/nurse.png" class="img1" alt="">
      </div>
      <div class="tabs-product">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in tabList" :key="index" :label="item" :name="String(index)" />
        </el-tabs>
      </div>
      <div class="tab-content" :style="tabStyle">
        <div ref="mySwiper" :options="swiperOption">
          <div v-if="activeName === '0'" class="swiper_slide_item">
            <div class="title">护理人员档案管理子系统</div>
            <img src="@/assets/product/nursing1.png" class="img1" alt="">
          </div>
          <div v-if="activeName === '1'" class="swiper_slide_item">
            <div class="title">护理不良事件上报与管理子系统</div>
            <div class="sub">系统可帮助医院建立针对护理隐患（未发生），护理不良事件（已发生）如：给药错误、针刺伤、护理投诉、护理事故、烫伤、压疮、高危压疮、坠床、跌倒、药物外渗、管道滑脱等情况进行的上报、管理、整改分析流程，通过系统流程约束，确保不良事件的及时改正，实现不良事件上报的准确性、及时性。可自动生成各类异常事件的统计情况，并生成可视化的图表，方便管理者查看。</div>
            <img src="@/assets/product/nursing2.png" class="img2" alt="">
          </div>
          <div v-if="activeName === '2'" class="swiper_slide_item">
            <div class="title">护理质量管理子系统</div>
            <div class="card">
              <div class="card-item">
                <div>覆盖不同的检查类型</div>
                <div>（三级质量检查、专项质量检查等）</div>
              </div>
              <div class="card-item">
                定制符合医院要求的护理质控模板
              </div>
              <div class="card-item">
                便于维护各项指标
              </div>
              <div class="card-item">
                支持不同的检查标准
              </div>
            </div>
            <img src="@/assets/product/nursing3.png" class="img3" alt="">
            <div class="bg">
              <img src="@/assets/product/nursing4.png" class="img4" alt="">
              <img src="@/assets/product/nursing5.png" class="img5" alt="">
            </div>
          </div>
          <div v-if="activeName === '3'" class="swiper_slide_item">
            <div class="title">护理排班管理子系统</div>
            <div class="sub">建立护理排班管理子系统，病区护士长或排班员可自行设置一套特有的排班班次（如日班、夜班等班次名称），各个排班班次对应的时间（如早班可能对应0.5天）。支持设置班次的颜色及先后排序，组排、批量复制排班等，方便排班及查阅。可设置排班约束条件，排班时系统智能提醒，如每周合理班时数不能超过40小时。通过基本功能模块，同时结合科室工作量需求，最终可实现科学合理地安排各科护理人力、提高护理服务质量的目标。</div>
            <img src="@/assets/product/nursing6.png" class="img6" alt="">
          </div>
          <div v-if="activeName === '4'" class="swiper_slide_item">
            <div class="title">护士站智能交互系统</div>
            <div class="sub">可根据统计了解个人排班、科室排班、夜班等是否合理。可根据统计护士个人排班需求满足次数，做到公平、公正，满足调班、请假需求。排班模块可以汇总统计护士的各个班次的数量，请假天数，夜班费数量，岗位统计，数据精准，同时节省了护理部人工统计的时间。另外通过班次的维护和his中获取的床位数和患者数可产生实时的床护比和护患比，为管理者进行人员调配提供了数据支持。</div>
            <img src="@/assets/product/nursing7.png" class="img7" alt="">
          </div>
          <div v-if="activeName === '5'" class="swiper_slide_item">
            <div class="title">护理教育培训子系统</div>
            <div class="sub">教育管理培训子系统可维护护士的护理科研成果、个人专业技术档案，维护的信息自动同步到个人的档案中。可配置学时学分督导规则，查看个人的学时学分达标情况。实现护士个人档案的实时更新，及时、精准的反馈护士个人的继续教育经历。</div>
            <img src="@/assets/product/nursing8.png" class="img8" alt="">
            <div class="sub sub2">
              <div class="text">根据护士的职称/层级等规则设置达标的学分要求</div>
              <div>通过修学分记录测算达标结果，一目了然</div>
            </div>
            <img src="@/assets/product/nursing9.png" class="img9" alt="">
          </div>
          <div v-if="activeName === '6'" class="swiper_slide_item">
            <div class="title">护理敏感指标管理子系统</div>
            <div class="sub">护理敏感指标管理系统依据等级医院评审标准中医疗质量指标要求，设立护患比、住院患者压疮发生率等指标的关键内容和科学测量方法，并对每个指标赋予了一个科学的权重，确定了护理质量指标评价标准。通过指标的采集和监测，进行多维度的分析，了解指标的发展趋势和变化规律，能够指导护理干预和质量控制。</div>
            <img src="@/assets/product/nursing10.png" class="img10" alt="">
          </div>
          <div v-if="activeName === '7'" class="swiper_slide_item">
            <div class="title">护理部决策分析系统</div>
            <div class="sub">用最简洁的方式为护理管理者提供最准确、最详实的数据分析结果，以便于护理管理者做出精准化防护力决策，制定下一个周期的工作方向，提升临床护理管理质量。</div>
            <img src="@/assets/product/nursing11.png" class="img11" alt="">
          </div>
        </div>
        <!-- <swiper ref="mySwiper" :options="swiperOption">
          <swiper-slide class="swiper_slide_item">
            <div class="title">护理人员档案管理子系统</div>
            <img src="@/assets/product/nursing1.png" class="img1" alt="">
          </swiper-slide>
          <swiper-slide class="swiper_slide_item">
            <div class="title">护理不良事件上报与管理子系统</div>
            <div class="sub">系统可帮助医院建立针对护理隐患（未发生），护理不良事件（已发生）如：给药错误、针刺伤、护理投诉、护理事故、烫伤、压疮、高危压疮、坠床、跌倒、药物外渗、管道滑脱等情况进行的上报、管理、整改分析流程，通过系统流程约束，确保不良事件的及时改正，实现不良事件上报的准确性、及时性。可自动生成各类异常事件的统计情况，并生成可视化的图表，方便管理者查看。</div>
            <img src="@/assets/product/nursing2.png" class="img2" alt="">
          </swiper-slide>
          <swiper-slide class="swiper_slide_item">
            <div class="title">护理质量管理子系统</div>
            <div class="card">
              <div class="card-item">
                <div>覆盖不同的检查类型</div>
                <div>（三级质量检查、专项质量检查等）</div>
              </div>
              <div class="card-item">
                定制符合医院要求的护理质控模板
              </div>
              <div class="card-item">
                便于维护各项指标
              </div>
              <div class="card-item">
                支持不同的检查标准
              </div>
            </div>
            <img src="@/assets/product/nursing3.png" class="img3" alt="">
            <div class="bg">
              <img src="@/assets/product/nursing4.png" class="img4" alt="">
              <img src="@/assets/product/nursing5.png" class="img5" alt="">
            </div>
          </swiper-slide>
          <swiper-slide class="swiper_slide_item">
            <div class="title">护理排班管理子系统</div>
            <div class="sub">建立护理排班管理子系统，病区护士长或排班员可自行设置一套特有的排班班次（如日班、夜班等班次名称），各个排班班次对应的时间（如早班可能对应0.5天）。支持设置班次的颜色及先后排序，组排、批量复制排班等，方便排班及查阅。可设置排班约束条件，排班时系统智能提醒，如每周合理班时数不能超过40小时。通过基本功能模块，同时结合科室工作量需求，最终可实现科学合理地安排各科护理人力、提高护理服务质量的目标。</div>
            <img src="@/assets/product/nursing6.png" class="img6" alt="">
          </swiper-slide>
          <swiper-slide class="swiper_slide_item">
            <div class="title">护士站智能交互系统</div>
            <div class="sub">可根据统计了解个人排班、科室排班、夜班等是否合理。可根据统计护士个人排班需求满足次数，做到公平、公正，满足调班、请假需求。排班模块可以汇总统计护士的各个班次的数量，请假天数，夜班费数量，岗位统计，数据精准，同时节省了护理部人工统计的时间。另外通过班次的维护和his中获取的床位数和患者数可产生实时的床护比和护患比，为管理者进行人员调配提供了数据支持。</div>
            <img src="@/assets/product/nursing7.png" class="img7" alt="">
          </swiper-slide>
          <swiper-slide class="swiper_slide_item">
            <div class="title">护理教育培训子系统</div>
            <div class="sub">教育管理培训子系统可维护护士的护理科研成果、个人专业技术档案，维护的信息自动同步到个人的档案中。可配置学时学分督导规则，查看个人的学时学分达标情况。实现护士个人档案的实时更新，及时、精准的反馈护士个人的继续教育经历。</div>
            <img src="@/assets/product/nursing8.png" class="img8" alt="">
            <div class="sub sub2">
              <div class="text">根据护士的职称/层级等规则设置达标的学分要求</div>
              <div>通过修学分记录测算达标结果，一目了然</div>
            </div>
            <img src="@/assets/product/nursing9.png" class="img9" alt="">
          </swiper-slide>
          <swiper-slide class="swiper_slide_item">
            <div class="title">护理敏感指标管理子系统</div>
            <div class="sub">护理敏感指标管理系统依据等级医院评审标准中医疗质量指标要求，设立护患比、住院患者压疮发生率等指标的关键内容和科学测量方法，并对每个指标赋予了一个科学的权重，确定了护理质量指标评价标准。通过指标的采集和监测，进行多维度的分析，了解指标的发展趋势和变化规律，能够指导护理干预和质量控制。</div>
            <img src="@/assets/product/nursing10.png" class="img10" alt="">
          </swiper-slide>
          <swiper-slide class="swiper_slide_item">
            <div class="title">护理部决策分析系统</div>
            <div class="sub">用最简洁的方式为护理管理者提供最准确、最详实的数据分析结果，以便于护理管理者做出精准化防护力决策，制定下一个周期的工作方向，提升临床护理管理质量。</div>
            <img src="@/assets/product/nursing11.png" class="img11" alt="">
          </swiper-slide>
        </swiper> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import 'swiper/css/swiper.css';
import Banner from '@/components/Banner';

export default {
  name: '',
  components: {
    // Swiper,
    // SwiperSlide,
    Banner
  },
  data() {
    return {
      activeName: '0',
      swiperOption: {
        direction: 'horizontal',
        simulateTouch: false
      },
      tabList: ['护理人员档案管理子系统', '护理不良事件上报与管理子系统', '护理质量管理子系统', '护理排班管理子系统', '护士站智能交互系统', '护理教育培训子系统', '护理敏感指标管理子系统', '护理部决策分析系统']
    };
  },
  computed: {
    tabStyle() {
      return {
        height: (this.activeName === '2' || this.activeName === '5') ? '1120px' : '580px'
      };
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClick(val) {
      // this.swiper.slideTo(Number(val.index), 700, false);
    }
  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .banner-wrapper {
    width: 100%;
    height: 560px;
    background: url('https://static.zhongjiadata.com/images/20250227/29081711d33a49a7a0cf860a7adb1ff6.png') no-repeat center center;
    background-size: cover;
    .banner-text {
      padding-top: 7px;
      width: 1400px;
      margin: 0 auto;
      padding-left: 110px;
      .text-title {
        font-weight: 670;
        color: #ffffff;
        line-height: 55px;
        font-size: 40px;
        margin-bottom: 20px;
      }
      .text-sub {
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        width: 500px;
      }
    }
  }
  .product-content {
    background: #fff;
    padding-bottom: 240px;
  }
  .introduction-content {
    width: 1234px;
    margin: 0 auto;
    margin-bottom: 120px;
    .img1 {
      width: 100%;
      height: 656px;
    }
  }
  .tabs-product {
    width: 1234px;
    margin: 0 auto;
    margin-bottom: 60px;
    /deep/.el-tabs {
      .el-tabs__nav {
        width: 100%;
        display: flex;
        .el-tabs__item {
          padding: 0;
          text-align: center;
          width: 180px;
          padding: 0 18px !important;
          font-size: 16px;
          color: #333;
          font-weight: normal;
          height: 60px;
          line-height: normal;
          white-space: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          &.is-active {
            color: #0279FF;
          }
        }
        .el-tabs__active-bar {
          color: #0279FF;
        }
      }
    }
  }
  .tab-content {
    width: 100%;
    .swiper_slide_item {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .title {
        width: 100%;
        font-weight: 600;
        text-align: center;
        color: #333333;
        line-height: 40px;
        font-size: 28px;
        margin-bottom: 20px;
      }
      .sub {
        color: #666666;
        line-height: 22px;
        font-size: 16px;
        width: 1234px;
        display: inline-block;
        text-align-last: left;
        margin-bottom: 56px;
      }
      .sub2 {
        width: 100%;
        text-align: center;
        padding: 20px 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
          margin-bottom: 10px;
        }
      }
      .card {
        width: 1200px;
        height: 80px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.05);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
        margin: 0 auto;
        margin-bottom: 16px;
        .card-item {
          height: 40px;
          border-right: 1px solid #ededed;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          width: 25%;
          &:last-child {
            border: none;
          }
          div {
            width: 100%;
          }
        }
      }
      .bg {
        width: 100%;
        height: 320px;
        background: linear-gradient(270deg,#38b9f7, #2767ff);
        position: relative;
        padding-top: 153px;
        text-align: center;
        .img4 {
          position: absolute;
          top: -124px;
          left: 50%;
          transform: translateX(-50%);
          width: 1284px;
          height: 247px;
        }
        .img5 {
          width: 1284px;
          height: 112px;
        }
      }
    }
    .img1 {
      width: 1233px;
    }
    .img2 {
      width: 1319px;
    }
    .img3 {
      width: 1403px;
      margin-bottom: 180px;
    }
    .img6 {
      width: 1234px;
    }
    .img7 {
      width: 1280px;
    }
    .img8 {
      width: 1280px;
    }
    .img9 {
       width: 1280px;
    }
    .img10 {
      width: 1262px;
    }
    .img11 {
      width: 1386px;
    }
  }
}
</style>
