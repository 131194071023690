import { render, staticRenderFns } from "./nursing.vue?vue&type=template&id=42d645a3&scoped=true&"
import script from "./nursing.vue?vue&type=script&lang=js&"
export * from "./nursing.vue?vue&type=script&lang=js&"
import style0 from "./nursing.vue?vue&type=style&index=0&id=42d645a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42d645a3",
  null
  
)

export default component.exports